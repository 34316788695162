import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import {
  Grid,
  Button,
  Card,
  ClickAwayListener,
  TextField,
  InputAdornment,
  Tooltip
} from "@material-ui/core";
import parse from "html-react-parser";
import axios from "axios";
import LoadingComponent from "components/LoadingComponent";
import MediaQuery from "react-responsive";
import SpacexWrapper from "../SpacexWrapper";
//import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { filter, includes } from "lodash";
import { SPACEX_LOGIN_EMAIL } from "config/constants";
import downArrow from "assets/images/down-arrow.svg";
import vector from "assets/images/vector.svg";
import familyIcon from "assets/images/familyIcon.svg";
import petIcon from "assets/images/petIcon.svg";
import hotFoodIcon from "assets/images/hot_food.png";
import drinkIcon from "assets/images/drink.png";
import wifiIcon from "assets/images/starlink_wifi.png";
import standardWifiIcon from "assets/images/standard_wifi.png";
import { withStyles } from "@material-ui/core/styles";

const FlightBookButton = ({
  flight,
  isMultiple,
  futureFlights,
  history,
  userData
}) => {
  // const [open, setOpen] = useState(false);
  // const [warningText, setWarningText] = useState("");
  // const [status, setStatus] = useState("");
  // const statusArr = ["pending", "checked in", "confirmed"];

  // const getText = flight => {
  //   if (flight.route_type === "connecting") {
  //     switch (flight.status) {
  //       case "available":
  //         let capacity = getCapacity(flight);
  //         return `${capacity} available ${pluralize(capacity, "spot")}`;
  //       case "waitlist":
  //         let total = getTotalWaitlist(flight);
  //         return `${total} waitlist ${pluralize(total, "spot")} available`;
  //       default:
  //         return "waitlist full";
  //     }
  //   }
  //   switch (flight.status) {
  //     case "available":
  //       return `${getCapacity(flight)}/${flight.capacity} available`;
  //     case "waitlist":
  //       let total = getTotalWaitlist(flight);
  //       return `${total} waitlist ${pluralize(total, "spot")} available`;
  //     default:
  //       return "waitlist full";
  //   }
  // };

  const isBrownsVille = flight => {
    return (flight.to_text && flight.to_text.includes("Brownsville")) ||
      (flight.stops && flight.stops.includes("BRO"))
      ? "1"
      : "0";
  };

  // const getTotalWaitlist = flight =>
  //   parseInt(flight.total_capacity) - parseInt(flight.total_passengers);

  // const getCapacity = flight =>
  //   parseInt(flight.capacity) - parseInt(flight.total_passengers);
  const isFull = flight => ["unavailable"].includes(flight.seats_text);

  const AvailabilityTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 250,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9"
    }
  }))(Tooltip);

  const handleIsAllow = () => {
    history.push({
      pathname: `/fly/personal-information/${flight.id}/${isBrownsVille(
        flight
      )}`,
      state: {
        isNda: flight.nda,
        isCharter: flight.charter,
        isMultiple: isMultiple,
        userData: userData
      }
    });
    // let checkArr =
    //   futureFlights.length > 0
    //     ? futureFlights.filter(
    //         val =>
    //           includes(statusArr, toLower(val.status)) &&
    //           val.from_text === flight.from_text
    //       )
    //     : [];
    // if (!isMultiple && checkArr.length > 0) {
    //   setOpen(true);
    //   setWarningText(
    //     `${checkArr[0].from_text} for ${moment(
    //       checkArr[0].from_date,
    //       "YYYY-MM-DD"
    //     ).format("ddd, MM-DD")}`
    //   );
    //   setStatus(checkArr[0].status);
    // } else {
    //   history.push({
    //     pathname: `/fly/personal-information/${flight.id}/${isBrownsVille(
    //       flight
    //     )}`,
    //     state: { isNda: flight.nda, isCharter: flight.charter }
    //   });
    // }
  };

  return (
    <>
      {/* <Link
        to={{
          pathname: `/fly/personal-information/${flight.id}/${isBrownsVille(
            flight
          )}`,
          state: { isNda: flight.nda }
        }}
        className={isFull(flight) ? "spacex-pointerEvent" : ""}
      > */}
      <Button
        className={`btnChoose  ${
          isFull(flight) ? "fl-result-btn-disable" : "fl-result-btn-chooseDept"
        }`}
        disabled={isFull(flight)}
        onClick={() => !isFull(flight) && handleIsAllow()}
      >
        {isFull(flight) ? "Unavailable" : "Choose"}
      </Button>
      {/* </Link> */}
      <MediaQuery minDeviceWidth={768}>
        <div className="availability">
          <AvailabilityTooltip
            title={
              <React.Fragment>
                {flight.status === "available" && (
                  <>
                    <span style={{ display: "block", fontSize: "1.2em" }}>
                      <b>{flight.seats_left}</b>/{flight.capacity} seats
                      available{" "}
                      {flight.family_friend !== "1" && "for employees"}
                    </span>
                    {flight.family_friend === "1" && (
                      <>
                        <span
                          style={{ display: "block", color: "#8B0000" }}
                          role="img"
                          aria-label="priority-warn"
                        >
                          ⚠️ seats will be prioritized for employees
                        </span>
                        <hr />
                        <span style={{ display: "block" }}>
                          {flight.subtotal_employee_passengers}/
                          {flight.capacity} reserved for employees
                        </span>
                        <span style={{ display: "block" }}>
                          {flight.subtotal_ff_passengers}/{flight.capacity}{" "}
                          reserved for family/friends
                        </span>
                      </>
                    )}
                  </>
                )}
                {flight.status === "standby" && (
                  <>
                    <span style={{ display: "block", fontSize: "1.2em" }}>
                      <b>{flight.seats_left}</b>/{flight.waitlist} standby
                      positions available{" "}
                      {flight.family_friend !== "1" && "for employees"}
                    </span>
                    {flight.family_friend === "1" && (
                      <>
                        <span
                          style={{ display: "block", color: "#8B0000" }}
                          role="img"
                          aria-label="priority-warn"
                        >
                          ⚠️ seats will be prioritized for employees
                        </span>
                        <hr />
                        <span style={{ display: "block" }}>
                          {flight.standby_employee}/{flight.waitlist} standby
                          for employees
                        </span>
                        <span style={{ display: "block" }}>
                          {flight.standby_ff}/{flight.waitlist} standby for
                          family/friends
                        </span>
                      </>
                    )}
                  </>
                )}
              </React.Fragment>
            }
          >
            <span>
              {flight.seats_text}{" "}
              <i className="fa fa-info-circle" aria-hidden="true"></i>
            </span>
          </AvailabilityTooltip>
        </div>
      </MediaQuery>

      {/* <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableEnforceFocus
      >
        <Card className={`warning-fly ${isMobile ? "warning-fly-mobile" : ""}`}>
          <Grid item={true} sm={12}>
            <p>
              You already have a reservation from {warningText} with a{" "}
              <span>{toLower(status)}</span> status.
            </p>
            <p>
              {toLower(status) === "checked in"
                ? "Following your current reservation, booking this flight will become available to you."
                : "Please cancel that reservation if you wish to book this flight."}
            </p>
          </Grid>
        </Card>
      </Modal> */}
    </>
  );
};

const ConnectingFlightText = ({ flight }) => {
  return (
    <>
      {flight.route_type === "connecting" && (
        <>
          <br />
          <span className="connectingFlight">
            <b>
              {flight.stops.length} connection in {flight.stops.join(", ")}
            </b>
          </span>
        </>
      )}
    </>
  );
};

const FlyResult = props => {
  const [flightArr, setFlightArr] = useState([]);
  const [filterArr, setFilterArr] = useState([]);
  const [errCount, setErrCount] = useState(0);
  const [isGetData, setIsGetData] = useState(0);
  const [flightFlag, setFlightFlag] = useState(false);
  // const [dateVal, setDateVal] = useState(null);
  // const [dateFocus, setDateFocus] = useState(false);
  const [filterSelect, setFilterSelect] = useState([]);
  const [selectVal, setSelectVal] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isMultiple, setIsMultiple] = useState(false);
  const [futureFlights, setFutureFlights] = useState([]);
  const [userData, setUserData] = useState([]);

  const flightCode = string =>
    string
      .split(" ")
      .pop()
      .replace(/[()]/g, "");

  const commonGetAvailableFlight = () => {
    let params = {
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment()
        .add(62, "days")
        .format("YYYY-MM-DD")
    };

    axios
      .post("/api/airplane/availability", params)
      .then(function(response) {
        response.data &&
          !response.data.error &&
          setFlightArr(response.data.flights);
        response.data &&
          !response.data.error &&
          setFilterArr(response.data.flights);
        response.data &&
          !response.data.error &&
          setFilterSelect(response.data.flights);
        response.data &&
          !response.data.error &&
          response.data.flights.length === 0 &&
          setIsGetData(1);

        response.data && response.data.error && setFlightArr([]);
        response.data && response.data.error && setFilterArr([]);
        response.data && response.data.error && setFilterSelect([]);
        response.data && response.data.error && setErrCount(1);
      })
      .catch(function(error) {
        console.log(error);
        setErrCount(1);
      });
  };

  // const handleDateChange = useCallback(
  //   val => {
  //     setDateVal(val);
  //     let flights = flightArr;
  //     let formatDate =
  //       val && moment(val) ? moment(val).format("YYYY-MM-DD") : null;
  //     let arr = filter(flights, flight => flight.from_date === formatDate);
  //     setFilterArr(arr);
  //     setDateFocus(false);
  //   },
  //   [flightArr]
  // );
  const handleSelectChange = useCallback(
    val => {
      if (val === "all flights") {
        setFilterArr(flightArr);
        return;
      }
      let flights = flightArr;
      let arr = filter(flights, flight => includes(flight.to_text, val));
      setFilterArr(arr);
    },
    [flightArr]
  );

  useEffect(() => {
    if (!flightFlag) {
      commonGetAvailableFlight();
      axios.get("/api/airplane/reservations").then(res => {
        res.data && setFutureFlights(res.data.flights);
        res.data && setUserData(res.data.user_data);
      });

      axios
        .post("/api/airplane/privilege", {
          email: sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
            ? sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
            : ""
        })
        .then(res => {
          res.data &&
            !res.data.error &&
            res.data.privilege &&
            setIsMultiple(res.data.privilege.multiple);
        })
        .catch(error => {
          console.log(error);
        });

      setFlightFlag(true);
    }
    return () => {};
  }, [flightFlag, flightArr, filterArr]);

  return (
    <SpacexWrapper>
      <div className="resultCard flyresult" style={{ position: "initial" }}>
        <div className="innerflyresult">
          <Grid container className="date-region">
            {/* <MediaQuery minDeviceWidth={768}> */}
            {/* <div className="date-inner">
              <SingleDatePicker
                id="flydate"
                date={dateVal}
                placeholder="Search by date"
                focused={dateFocus}
                onFocusChange={() => setDateFocus(true)}
                onDateChange={value => handleDateChange(value)}
                numberOfMonths={1}
                navPrev={<div className="arrow-left" id="prevMonth"></div>}
                navNext={<div className="arrow-right" id="nextMonth"></div>}
                displayFormat="ddd, MMM DD YYYY"
                hideKeyboardShortcutsPanel={true}
                keepOpenOnDateSelect={true}
                onClose={() => setDateFocus(false)}
                showDefaultInputIcon
                inputIconPosition="after"
                daySize={30}
                transitionDuration={0}
                readOnly
              />
            </div> */}
            <div className="date-inner">
              <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <div className="date-inner-box">
                  <TextField
                    onFocus={() => setIsOpen(true)}
                    value={selectVal}
                    placeholder="Select Destination"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={() => setIsOpen(true)}
                        >
                          <img
                            src={downArrow}
                            alt="Down Arrow"
                            style={{ cursor: "pointer" }}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                  {isOpen && (
                    <Card>
                      <Grid item={true} sm={12}>
                        {filterSelect.length > 0 && (
                          <span
                            onClick={() => {
                              setSelectVal("all flights");
                              handleSelectChange("all flights");
                              setIsOpen(false);
                            }}
                          >
                            all flights
                          </span>
                        )}
                        {filterSelect.length > 0 &&
                          filterSelect
                            .filter((thing, index, self) => {
                              return (
                                index ===
                                self.findIndex(t => t.to_text === thing.to_text)
                              );
                            })
                            .map((tval, key) => {
                              return (
                                <span
                                  key={key}
                                  onClick={() => {
                                    setSelectVal(
                                      `to ${flightCode(tval.to_text)}`
                                    );
                                    handleSelectChange(
                                      flightCode(tval.to_text)
                                    );
                                    setIsOpen(false);
                                  }}
                                >{`to ${flightCode(tval.to_text)}`}</span>
                              );
                            })}
                      </Grid>
                    </Card>
                  )}
                </div>
              </ClickAwayListener>
              <span
                style={{
                  lineHeight: "26px",
                  paddingTop: "7px",
                  margin: "0px",
                  color: "white",
                  display: "block",
                  float: "right",
                  fontSize: "14px",
                  textAlign: "center"
                }}
              >
                <span>&#9432;</span> Questions about booking, pets,
                friends/family or anything flight related?{" "}
                <a
                  href="https://sharex2016.spacex.corp/sites/boca/Pages/Flights-to-Starbase.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontWeight: "bold",
                    color: "#1dc2ff"
                  }}
                >
                  Click here
                </a>
              </span>
            </div>
            {/* </MediaQuery> */}
          </Grid>

          {/* Desktop or laptop For Result List*/}
          {/* original minDeviceWdith={1224} */}
          <MediaQuery minDeviceWidth={768}>
            <Grid container className="serachDetail flysearchDetail">
              {filterArr.length > 0 &&
                filterArr.map((flight, key) => {
                  return (
                    <React.Fragment key={key}>
                      <Grid container className="divDetail">
                        <Grid item sm={12} xs={12} className="flydetailBox">
                          <Grid container>
                            <Grid item sm={2} xs={7} className="flyinfoSection">
                              <span>
                                {flight.from_date
                                  ? parse(
                                      moment(flight.from_date, "YYYY-MM-DD")
                                        .format("MMM Do")
                                        .replace(
                                          /(\d)(st|nd|rd|th)/g,
                                          "$1<sup>$2</sup>"
                                        )
                                    )
                                  : ""}
                              </span>
                              <span>
                                {flight.from_date
                                  ? moment(
                                      flight.from_date,
                                      "YYYY-MM-DD"
                                    ).format("dddd")
                                  : ""}
                              </span>
                            </Grid>
                            <Grid
                              item
                              sm={3}
                              xs={12}
                              className="flyarrivalInfo"
                            >
                              <span className="infoType">Departs</span>
                              <h5>
                                {flight.from_time
                                  ? moment(flight.from_time, "HH:mm:ss").format(
                                      "hh:mm A"
                                    ) +
                                    (flight.from_timezone &&
                                    flight.from_timezone.abbr
                                      ? flight.from_timezone.abbr === "unknown"
                                        ? ` UTC${flight.from_timezone.offset}`
                                        : ` ${flight.from_timezone.abbr.toUpperCase()}`
                                      : "")
                                  : ""}
                              </h5>
                              <span className="flightDestination">
                                <span className="firstPhrase">
                                  {flight.from_text.includes("(")
                                    ? flight.from_text
                                        .split("(")[1]
                                        .replace(")", "")
                                    : ""}
                                </span>
                                <span className="secondPhrase">
                                  {flight.from_text.includes("(")
                                    ? flight.from_text.split("(")[0]
                                    : ""}
                                </span>
                              </span>
                              <ConnectingFlightText flight={flight} />
                              <span className="vectorSection">
                                <img src={vector} alt="" />
                              </span>
                            </Grid>
                            <Grid
                              item
                              sm={2}
                              xs={12}
                              className="flyarrivalInfo"
                            >
                              <span className="infoType">Arrives</span>
                              <h5>
                                {flight.to_time
                                  ? moment(flight.to_time, "HH:mm:ss").format(
                                      "hh:mm A"
                                    ) +
                                    (flight.to_timezone &&
                                    flight.to_timezone.abbr
                                      ? flight.to_timezone.abbr === "unknown"
                                        ? ` UTC${flight.to_timezone.offset}`
                                        : ` ${flight.to_timezone.abbr.toUpperCase()}`
                                      : "")
                                  : ""}
                              </h5>
                              <span className="flightDestination">
                                <span className="firstPhrase">
                                  {flight.to_text.includes("(")
                                    ? flight.to_text
                                        .split("(")[1]
                                        .replace(")", "")
                                    : ""}
                                </span>
                                <span className="secondPhrase">
                                  {flight.to_text.includes("(")
                                    ? flight.to_text.split("(")[0]
                                    : ""}
                                </span>
                              </span>
                            </Grid>
                            <Grid item sm={3} xs={1} className="iconSection">
                              <div></div>
                              <div>
                                {Number(flight.facilities.standard_wifi) ===
                                  1 && (
                                  <span>
                                    <Tooltip
                                      title="Standard WiFi available"
                                      placement="top"
                                    >
                                      <img src={standardWifiIcon} alt="" />
                                    </Tooltip>
                                  </span>
                                )}
                                {Number(flight.facilities.starlink_wifi) ===
                                  1 && (
                                  <span>
                                    <Tooltip
                                      title="Starlink WiFi available"
                                      placement="top"
                                    >
                                      <img src={wifiIcon} alt="" />
                                    </Tooltip>
                                  </span>
                                )}
                                {Number(flight.facilities.drink) === 1 && (
                                  <span>
                                    <Tooltip
                                      title="Drink available"
                                      placement="top"
                                    >
                                      <img src={drinkIcon} alt="" />
                                    </Tooltip>
                                  </span>
                                )}
                                {Number(flight.facilities.hot_food) === 1 && (
                                  <span>
                                    <Tooltip
                                      title="Hot food available"
                                      placement="top"
                                    >
                                      <img src={hotFoodIcon} alt="" />
                                    </Tooltip>
                                  </span>
                                )}
                                {Number(flight.family_friend) === 1 && (
                                  <span>
                                    <Tooltip
                                      title="Family/Friends travel available"
                                      placement="top"
                                    >
                                      <img src={familyIcon} alt="" />
                                    </Tooltip>
                                  </span>
                                )}
                                {Number(flight.animal) === 1 && (
                                  <span>
                                    <Tooltip
                                      title="Pet friendly flight"
                                      placement="top"
                                    >
                                      <img src={petIcon} alt="" />
                                    </Tooltip>
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item sm={2} xs={12} className="flightBookBtn">
                              <FlightBookButton
                                userData={userData}
                                flight={flight}
                                isMultiple={isMultiple}
                                futureFlights={futureFlights}
                                history={props.history}
                              />
                            </Grid>
                            {flight.flight_warning_toggle === "1" && (
                              <Grid
                                item
                                sm={12}
                                xs={12}
                                className="warningText"
                              >
                                {flight.flight_warning_note}
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
                })}
            </Grid>
          </MediaQuery>

          {/* Tablet or Mobile For Result List*/}
          {/* maxWidth={1224} original */}
          <MediaQuery maxWidth={780}>
            <Grid container className="serachDetail flysearchDetail">
              {filterArr.length > 0 &&
                filterArr.map((flight, key) => {
                  let getToText = flight.to_text.split("(");
                  const toText =
                    getToText.length > 0
                      ? getToText[1]
                        ? getToText[1].replace(")", "")
                        : ""
                      : "";
                  let getFromText = flight.from_text.split("(");
                  const fromText =
                    getFromText.length > 0
                      ? getFromText[1]
                        ? getFromText[1].replace(")", "")
                        : ""
                      : "";

                  return (
                    <React.Fragment key={key}>
                      <Grid container className="divDetail">
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          className="flydetailBox mobile"
                        >
                          <Grid container>
                            <Grid item sm={7} xs={7} className="flyinfoSection">
                              <span>
                                {flight.from_date
                                  ? parse(
                                      moment(flight.from_date, "YYYY-MM-DD")
                                        .format("dddd, MMM Do")
                                        .replace(
                                          /(\d)(st|nd|rd|th)/g,
                                          "$1<sup>$2</sup>"
                                        )
                                    )
                                  : ""}
                              </span>
                            </Grid>
                            <Grid item sm={5} xs={5} className="flyAvaSection">
                              <span className="availability">
                                {flight.seats_text}
                              </span>
                            </Grid>

                            <Grid
                              item
                              sm={5}
                              xs={6}
                              className="flyarrivalInfo mobile"
                            >
                              <span className="infoType">Departs</span>
                              <h5>
                                {flight.from_time
                                  ? moment(flight.from_time, "HH:mm:ss").format(
                                      "hh:mm A"
                                    ) +
                                    (flight.from_timezone &&
                                    flight.from_timezone.abbr
                                      ? flight.from_timezone.abbr === "unknown"
                                        ? ` UTC${flight.from_timezone.offset}`
                                        : ` ${flight.from_timezone.abbr.toUpperCase()}`
                                      : "")
                                  : ""}
                              </h5>
                              <span className="flightDestination">
                                <span className="firstPhrase">{fromText}</span>
                                <span className="secondPhrase">
                                  {flight.from_text.split("(")[0]}
                                </span>
                              </span>
                              <ConnectingFlightText flight={flight} />
                              <span className="vectorSection">
                                <img src={vector} alt="" />
                              </span>
                            </Grid>
                            <Grid
                              item
                              sm={5}
                              xs={6}
                              className="flyarrivalInfo mobile"
                            >
                              <span className="infoType">Arrives</span>
                              <h5>
                                {flight.to_time
                                  ? moment(flight.to_time, "HH:mm:ss").format(
                                      "hh:mm A"
                                    ) +
                                    (flight.to_timezone &&
                                    flight.to_timezone.abbr
                                      ? flight.to_timezone.abbr === "unknown"
                                        ? ` UTC${flight.to_timezone.offset}`
                                        : ` ${flight.to_timezone.abbr.toUpperCase()}`
                                      : "")
                                  : ""}
                              </h5>
                              <span className="flightDestination">
                                <span className="firstPhrase">{toText}</span>
                                <span className="secondPhrase">
                                  {flight.to_text.split("(")[0]}
                                </span>
                              </span>
                            </Grid>
                            <div className="dividedMobile">&nbsp;</div>
                            <Grid item sm={5} xs={8} className="iconSection">
                              <div>
                                {Number(flight.facilities.standard_wifi) ===
                                  1 && (
                                  <span>
                                    <Tooltip
                                      title="Standard WiFi available"
                                      placement="top"
                                    >
                                      <img src={standardWifiIcon} alt="" />
                                    </Tooltip>
                                  </span>
                                )}
                                {Number(flight.facilities.starlink_wifi) ===
                                  1 && (
                                  <span>
                                    <Tooltip
                                      title="Starlink WiFi available"
                                      placement="top"
                                    >
                                      <img src={wifiIcon} alt="" />
                                    </Tooltip>
                                  </span>
                                )}
                                {Number(flight.facilities.drink) === 1 && (
                                  <span>
                                    <Tooltip
                                      title="Drink available"
                                      placement="top"
                                    >
                                      <img src={drinkIcon} alt="" />
                                    </Tooltip>
                                  </span>
                                )}
                                {Number(flight.facilities.hot_food) === 1 && (
                                  <span>
                                    <Tooltip
                                      title="Hot food available"
                                      placement="top"
                                    >
                                      <img src={hotFoodIcon} alt="" />
                                    </Tooltip>
                                  </span>
                                )}
                                {Number(flight.family_friend) === 1 && (
                                  <span>
                                    <Tooltip
                                      title="Family/Friends travel available"
                                      placement="top"
                                    >
                                      <img src={familyIcon} alt="" />
                                    </Tooltip>
                                  </span>
                                )}
                                {Number(flight.animal) === 1 && (
                                  <span>
                                    <Tooltip
                                      title="Pet friendly flight"
                                      placement="top"
                                    >
                                      <img src={petIcon} alt="" />
                                    </Tooltip>
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid item sm={7} xs={4} className="flightBookBtn">
                              <FlightBookButton
                                flight={flight}
                                isMultiple={isMultiple}
                                futureFlights={futureFlights}
                                history={props.history}
                              />
                            </Grid>
                            {flight.flight_warning_toggle === "1" && (
                              <Grid
                                item
                                sm={12}
                                xs={12}
                                className="warningText"
                              >
                                {flight.flight_warning_note}
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
                })}
            </Grid>
          </MediaQuery>

          {flightArr.length === 0 && errCount === 0 && isGetData === 0 && (
            <Grid container className="serachDetail">
              <LoadingComponent />
            </Grid>
          )}

          {flightArr.length === 0 && (errCount === 1 || isGetData === 1) && (
            <Grid container>
              <span className="spacex-noflyMsg" style={{ padding: "20px" }}>
                We are sorry. There are no upcoming flights.
              </span>
            </Grid>
          )}

          {flightArr.length !== 0 &&
            filterArr.length === 0 &&
            errCount === 0 &&
            isGetData === 0 && (
              <Grid container>
                <span className="spacex-noflyMsg" style={{ padding: "20px" }}>
                  There are no flights on this date.
                </span>
              </Grid>
            )}

          <Grid container>
            <MediaQuery minDeviceWidth={768}>
              <h2 className="flysendTitle" style={{ padding: "10px" }}>
                <ul>
                  <li>
                    Please note times are subject to changes &amp;
                    cancellations.
                  </li>
                  <li>
                    If none of the below flight options work for you, please
                    book a commercial flight at{" "}
                    <a
                      className="travel-link"
                      href="https://travel/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://travel
                    </a>{" "}
                    (VPN).
                  </li>
                  <li>
                    If flying commercial, note that United and American Airlines
                    fly to Brownsville with connections.
                  </li>
                  <li>
                    Do you have a critical flight need that is not listed or
                    have any feedback for the Flight team?
                    <br />
                    Please email:&nbsp;
                    <b>
                      <a
                        href="mailto:StarbaseAirFeedback@spacex.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        StarbaseAirFeedback@spacex.com
                      </a>
                    </b>
                  </li>
                </ul>
              </h2>
            </MediaQuery>
          </Grid>
        </div>
      </div>
    </SpacexWrapper>
  );
};

export default FlyResult;
