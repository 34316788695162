import React, { useState, useEffect } from "react";
import Axios from "axios";
import moment from "moment";
import "assets/scss/SleepDoorCode.scss";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import SleepImage from "./SleepImage";

const DoorCodeContainer = props => {
  return (
    <div className="SleepDoorCode">
      <div className="wrapper">
        <h2 className="title" style={{ textAlign: "center" }}>
          Your Reservation Details
        </h2>
        <div className="wrapper">{props.children}</div>
      </div>
    </div>
  );
};

const UpdateSleep = ({ status, bookingCode }) => {
  const isWaitingForGuest = () => status === "waiting for guest";

  return (
    isWaitingForGuest() && (
      <div>
        <p className="booking-id" style={{ paddingTop: "20px" }}>
          To update your reservation:
        </p>
        <div>
          <Link to={`/sleep/checkin/${bookingCode}`}>
            <Button className="btnChoose">I'm checked in</Button>
          </Link>
        </div>
        {/*<div>*/}
        {/*  <Button className="btnChoose">Please extend my reservation</Button>*/}
        {/*</div>*/}
      </div>
    )
  );
};

const SleepDoorCode = ({ history, match }) => {
  const CHECK_RESERVATION = "/public/reservation/info";

  const [room, setRoom] = useState(false);
  const getDate = date => moment(date).format("dddd, MMMM D");

  useEffect(() => {
    Axios.post(CHECK_RESERVATION, {
      bookingCode: match.params.bookingCode
    })
      .then(res => res.data && setRoom(res.data.data))
      .catch(err => setRoom({}));
    //eslint-disable-next-line
  }, []);

  if (!room) {
    return null;
  }

  switch (room && room.status) {
    case "not finalized":
      return (
        <DoorCodeContainer>
          <p className="booking-id">
            Your room assignment and door code have not been finalized yet and
            will be ready by
            <span>
              {room.access_time} {room.timezone}
            </span>
            on <span>{getDate(room.check_in)}</span>
          </p>
        </DoorCodeContainer>
      );
    case "finalized":
      return (
        <DoorCodeContainer>
          <p className="booking-id">
            You will be staying in room <span>{room.room_type}</span> in room
            number <span>{room.room_number}</span>.
          </p>
          <p className="booking-id">
            The door code is <span>{room.door_code}</span>.
          </p>
          {room.map_url && room.map_url !== "" && (
            <p className="booking-id">
              Google Maps Pin{" "}
              <span>
                <a
                  href={room.map_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Here
                </a>
              </span>
            </p>
          )}
          <br />

          <div className="warning-text">
            <b>
              For Airstreams: A door key is available for you to lock your
              airstream if you prefer. Please use the door code above to access
              the lock box. Lock box is located inside your airstream, inside
              the closet.
            </b>
            <br />
            <br />
            <span className="star-text">
              **You are responsible to return the key before checking out
              <br />
              **Any lost keys must be reported to StarbaseLiving immediately
            </span>
          </div>
          <UpdateSleep
            status={room.reservation_status}
            bookingCode={match.params.bookingCode}
          />
          {<SleepImage />}
        </DoorCodeContainer>
      );
    case "not been generated":
      return (
        <DoorCodeContainer>
          <p className="booking-id">
            You will be staying in room <span>{room.room_type}</span> in room
            number <span>{room.room_number}</span>.
          </p>
          <p className="booking-id">
            The door code is <span>No Code</span>.
          </p>
          <br />
          <div className="warning-text">
            <b>
              For Airstreams: A door key is available for you to lock your
              airstream if you prefer. Please use the door code above to access
              the lock box. Lock box is located inside your airstream, inside
              the closet.
            </b>
            <br />
            <br />
            <span className="star-text">
              **You are responsible to return the key before checking out
              <br />
              **Any lost keys must be reported to StarbaseLiving immediately
            </span>
          </div>
          {<SleepImage />}
        </DoorCodeContainer>
      );
    default:
      return (
        <div className="SleepDoorCode">
          <div className="wrapper">
            <h2 className="title">Reservation Not Found</h2>
          </div>
        </div>
      );
  }
};

export default SleepDoorCode;
