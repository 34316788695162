import React, { useEffect, useState } from "react";
import "assets/scss/SleepDoorCode.scss";
import Axios from "axios";
import { Button, CircularProgress } from "@material-ui/core";
import SleepImage from "./SleepImage";

const SleepCheckin = ({ history, match }) => {
  const [room, setRoom] = useState(null);
  const [flag, setFlag] = useState(false);
  const [showCheckin, setShowCheckin] = useState(false);
  const [showCheckinCancel, setShowCheckinCancel] = useState(false);
  const [status, setStatus] = useState(null);
  const [checkinLoading, setCheckinLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const CHECK_RESERVATION = "/public/reservation/info";

  useEffect(() => {
    if (!flag) {
      Axios.post(CHECK_RESERVATION, {
        bookingCode: window.location.pathname.split("/")[3]
      })
        .then(res => res.data && setRoom(res.data.data))
        .catch(err => {
          setRoom(null);
          setStatus("not found");
        });
      setFlag(true);
    }
  }, [flag]);

  const checkinSleepBooking = () => {
    Axios.post("/public/reservation/checkinSleepBooking", {
      bookingCode: window.location.pathname.split("/")[3]
    })
      .then(
        res =>
          res.data &&
          history.push({
            pathname: "/sleep/checkin-confirmation",
            state: { roomData: room }
          })
      )
      .catch(err => history.push("/sleep/checkin-confirmation"));
  };

  const checkinCancelSleepBooking = () => {
    Axios.post("/public/reservation/cancelSleepBooking", {
      bookingCode: window.location.pathname.split("/")[3]
    })
      .then(res => res.data && history.push("/sleep/checkin-cancel"))
      .catch(err => history.push("/sleep/checkin-cancel"));
  };

  return (
    <div className="SleepDoorCode">
      <div className="wrapper">
        {status === "not found" ? (
          <h2 className="title">Reservation Not Found</h2>
        ) : (
          <>
            <h2 className="title" style={{ textAlign: "center" }}>
              Your Reservation Details
            </h2>
            <p className="booking-id">
              You are staying in <span>{room && room.room_type}</span>
              {room && room.reservation_status === "checked in" && (
                <>
                  <br /> in room number <span>{room && room.room_number}</span>
                  <br /> The door code is{" "}
                  <span>
                    {room && room.door_code !== "" ? room.door_code : "No Code"}
                  </span>
                </>
              )}
              <br /> check-in date <span>{room && room.check_in}</span>
              {room && room.map_url && room.map_url !== "" && (
                <p className="booking-id">
                  Google Maps Pin{" "}
                  <span>
                    <a
                      href={room.map_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Here
                    </a>
                  </span>
                </p>
              )}
            </p>
            {room && room.map_url && room.map_url !== "" && (
              <p className="booking-id">
                Google Maps Pin{" "}
                <span>
                  <a
                    href={room.map_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Here
                  </a>
                </span>
              </p>
            )}
            <br />

            {room && room.reservation_status === "checked in" && (
              <div style={{ textAlign: "center" }}>
                <h2
                  className="title"
                  style={{
                    textAlign: "center",
                    marginBottom: "0px",
                    marginTop: "0px"
                  }}
                >
                  You have been successfully checked in!
                </h2>
              </div>
            )}
            {room && room.reservation_status === "checked in" && (
              <div className="warning-text">
                <br />
                <b>
                  For Airstreams: A door key is available for you to lock your
                  airstream if you prefer. Please use the door code above to
                  access the lock box. Lock box is located inside your
                  airstream, inside the closet.
                </b>
                <br />
                <br />
                <span className="star-text">
                  **You are responsible to return the key before checking out
                  <br />
                  **Any lost keys must be reported to StarbaseLiving immediately
                </span>
              </div>
            )}

            {room && room.reservation_status === "canceled" && (
              <div style={{ textAlign: "center" }}>
                <h2
                  className="title"
                  style={{ textAlign: "center", marginBottom: "0px" }}
                >
                  Your reservation has been canceled.
                </h2>
              </div>
            )}

            {!showCheckin &&
              !showCheckinCancel &&
              room &&
              room.reservation_status !== "checked in" &&
              room.reservation_status !== "canceled" && (
                <div style={{ textAlign: "center" }}>
                  <h2
                    className="title"
                    style={{ textAlign: "center", marginBottom: "0px" }}
                  >
                    Kindly press the button below <br />
                    to check-in or cancel your reservation
                  </h2>
                  <Button
                    className="btnContinue btnCheckoutYes"
                    onClick={() => setShowCheckin(true)}
                  >
                    Check-In Reservation
                  </Button>
                  <Button
                    className="btnContinue btnCheckoutDanger"
                    onClick={() => setShowCheckinCancel(true)}
                    style={{}}
                  >
                    Cancel Reservation
                  </Button>
                </div>
              )}
            {showCheckin &&
              room &&
              room.reservation_status !== "checked in" &&
              room.reservation_status !== "canceled" && (
                <div style={{ textAlign: "center" }}>
                  <h2
                    className="title"
                    style={{ textAlign: "center", marginBottom: "0px" }}
                  >
                    Are you sure you want to proceed with the check-in?
                  </h2>
                  <Button
                    className="btnContinue btnCheckoutYes"
                    onClick={() => {
                      setCheckinLoading(true);
                      checkinSleepBooking();
                    }}
                    disabled={checkinLoading}
                  >
                    Yes {checkinLoading && <CircularProgress />}
                  </Button>
                  <Button
                    className="btnContinue btnCheckoutNo"
                    onClick={() => setShowCheckin(false)}
                  >
                    No
                  </Button>
                </div>
              )}
            {showCheckinCancel &&
              room &&
              room.reservation_status !== "checked in" &&
              room.reservation_status !== "canceled" && (
                <div style={{ textAlign: "center" }}>
                  <h2
                    className="title"
                    style={{ textAlign: "center", marginBottom: "0px" }}
                  >
                    Are you sure you want to cancel your reservation?
                  </h2>
                  <Button
                    className="btnContinue btnCheckoutDanger btnCheckinCancelResConfirmation"
                    onClick={() => {
                      setCancelLoading(true);
                      checkinCancelSleepBooking();
                    }}
                    disabled={cancelLoading}
                  >
                    Yes {cancelLoading && <CircularProgress />}
                  </Button>
                  <Button
                    className="btnContinue btnCheckoutNo"
                    onClick={() => setShowCheckinCancel(false)}
                  >
                    No
                  </Button>
                </div>
              )}

            <SleepImage />
          </>
        )}
      </div>
    </div>
  );
};

export default SleepCheckin;
